// font awesome
import {
  config,
  library,
  dom,
} from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

config.searchPseudoElements = true

library.add(fas, far, fab)
dom.watch()

// owl carousel
$(document).ready(function () {
  $('.reference_carousel').owlCarousel({
    loop: true,
    margin: 25,
    dots: true,
    nav: false,
    items: 1,
  })
})

// random background images
var bgColorArray = ['../../../src/img/bg1.jpg', '../../../src/img/bg2.jpg', '../../../src/img/bg3.jpg', '../../../src/img/bg4.jpg', '../../../src/img/bg5.jpg', '../../../src/img/bg6.jpg']
var selectBG = bgColorArray[Math.floor(Math.random() * bgColorArray.length)]
$('.bg_images').css('background', 'url(' + selectBG + ')')

// make menu items open on hover
if ($(window).width() > 768) {
  $('ul li.dropdown').hover(function () {
    $(this).find('.dropdown-menu').stop(true, true).fadeIn(500)
  }, function () {
    $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeOut(500)
  })
}
